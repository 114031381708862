import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios';
import Vuelidate from 'vuelidate'
import VueAlertify from 'vue-alertify'
import InfiniteLoading from 'vue-infinite-loading';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(VueAlertify)

Vue.config.productionTip = false


Vue.prototype.$http = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: { "Content-Type": "application/json" }
});
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token

}
Vue.use(InfiniteLoading, {
  props: {
    spinner: 'spiral',
    /* other props need to configure */

  }
});
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
