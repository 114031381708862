import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user : {
            fullname:'',
            role:''
        },
    },
    mutations: { //sync
        user(state,user){
            state.user=user;
        },
    },
    actions: { //async
    },
    getters:{

    },
    modules: {
    }
})

