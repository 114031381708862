import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'signin',
        component: () => import(/* webpackChunkName: "signin" */ '../components/signin')
    },
    {
        path: '',
        name:'Layout',
        component: () => import(/* webpackChunkName: "Layout" */ '../components/Layout'),

        children: [

            {
                path: '/home',
                name: 'Home',
                component: () => import(/* webpackChunkName: "Home" */ '../views/Home')
            },
            {
                path: '/clients',
                name: 'Clients',
                component: () => import(/* webpackChunkName: "Clients" */ '../views/Clients')
            },
            {
                path: '/parties',
                name: 'Parties',
                component: () => import(/* webpackChunkName: "Parties" */ '../views/Parties')
            },
            {
                path: '/devices',
                name: 'Devices',
                component: () => import(/* webpackChunkName: "Devices" */ '../views/Devices')
            },
            {
                path: '/payments',
                name: 'Payments',
                component: () => import(/* webpackChunkName: "Payments" */ '../views/Payments')
            },
        ]
    }



]

const router = new VueRouter({
    routes,
    mode:'history'
})

export default router
